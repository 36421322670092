.libre-franklin-regular{
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.courier-prime-regular {
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
}

.courier-prime-bold {
  font-family: "Courier Prime", monospace;
  font-weight: 700;
  font-style: normal;
}

.courier-prime-regular-italic {
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: italic;
}

.courier-prime-bold-italic {
  font-family: "Courier Prime", monospace;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Zuume Rough";
  src: url(fonts/fontspring-demo-zuumerough-bold.otf) format("opentype")
}


body {
  margin: 0 auto;
  margin-top: 85px;
  /* font-weight: lighter; */
  font-family: "Libre Franklin", "Source Sans 3", sans-serif;
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
    /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  position: relative;
  display:flex;
  
  
  
  
}

.events-page{
  
  min-width: 100vw;
  
}

.events-page-foot{
  /* l */
}

.app{
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  margin-top: auto;
  position: relative;
  padding-bottom: 6rem;
  
 
}
.footer2{
  background: none;
}



.homepage{
  background-image: url("./protest.jpg");
  
  background-position: fixed;
  position: fixed;
  overflow-y: scroll;
  margin-top: 5.3rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 160px;
  
  /* overflow: scroll; */
  
}

.nav-bar{
  align-items: center;
  display: flex;
  flex-direction: row;
}

.countdown{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 3.92rem;
}


.date-labels{

  color: #ffffff;
  font-weight: 3;
  font-size: 1.875rem;
  text-align: center;
  padding: 0.3em;
  text-transform: uppercase;
  /* font-family: "Work Sans"; */

}




.time{
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 4.5rem;
  /* font-family: "Courier Prime" */
}

.time2{
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 2.5rem;
  /* font-family: "Courier Prime" */
}


.about-body{
  /* margin-left: 5%;
  width: 80%; */
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  margin-left: 8%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media screen and (max-width: 768px) {
  .date-labels {
    font-size: 1.5rem; /* Decrease font size for smaller screens */
  }
  
  .expl {
    font-size: 1.5rem; /* Further decrease font size for even smaller screens */
  }

  .countdown-head {
    font-size: 1.5rem; /* Further decrease font size for even smaller screens */
  }

  .time {
    font-size: 3.5rem; /* Decrease font size for smaller screens */
  }

  .time2 {
    font-size: 2rem; /* Decrease font size for smaller screens */
  }
}

@media screen and (max-width: 624px) {
  .date-labels {
    font-size: 1.25rem; /* Decrease font size for smaller screens */
  }
  
  .expl {
    font-size: 1.25rem; /* Further decrease font size for even smaller screens */
  }

  .countdown-head {
    font-size: 1.25rem; /* Further decrease font size for even smaller screens */
  }

  .time {
    font-size: 3.2rem; /* Decrease font size for smaller screens */
  }

  .time2 {
    font-size: 1.75rem; /* Decrease font size for smaller screens */
  }

  .link-styles {
    font-size: 1rem; /* Further decrease font size for even smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .date-labels {
    font-size: 1.1rem; /* Further decrease font size for even smaller screens */
  }

  .expl {
    font-size: 1rem; /* Further decrease font size for even smaller screens */
  }

  .link-styles {
    font-size: 0.75rem; /* Further decrease font size for even smaller screens */
  }

  .countdown-head {
    font-size: 1rem; /* Further decrease font size for even smaller screens */
  }

  .time {
    font-size: 2.7rem; /* Further decrease font size for even smaller screens */
  }

  .time2 {
    font-size: 1.5rem; /* Decrease font size for smaller screens */
  }
}

@media screen and (max-width: 375px) {
  .date-labels {
    font-size: 1rem; /* Further decrease font size for even smaller screens */
  }

  .expl {
    font-size: 0.9rem; /* Further decrease font size for even smaller screens */
  }

  .link-styles {
    font-size: 0.7rem; /* Further decrease font size for even smaller screens */
  }

  .countdown-head {
    font-size: 0.9rem; /* Further decrease font size for even smaller screens */
  }

  .time {
    font-size: 2rem; /* Further decrease font size for even smaller screens */
  }

  .time2 {
    font-size: 1.25rem; /* Decrease font size for smaller screens */
  }
}

@media (max-width: 768px) {
  /* .side {
      display: none;
  }
  
  .side-content {
    display: none;
  } */

  .events-page-foot {
    
  }

  .events-page-foot .fa-twitter{
    left: 37%; 
  }

  .events-page-foot .fa-instagram{
    left: 49%; 
  }

  /* .about-body{
    top: 0;
  } */
}

/* @media (max-width: 1000px) {
  .side {
    display: none;
}

.side-content {
  display: none;
}
} */

.red {
  color: #EE2A35;
}

.white {
  color: #ffffff;
}

.footer{
  background-image: url("./footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  /* background: #454545; */

   position:absolute;
   resize:horizontal;
    
    height:6rem;
    display:flex;
    flex-shrink: inherit;
    
    width:auto;
    
    bottom:0;
    left:0;
    right:0;




  }


/* .date-labels-container {
  display: flex;
  justify-content: center; 
   align-items: center; 
} */

.nav-logo{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-left: 1em;

  padding-top: 0.2em;
  padding-bottom: 0.05em;

}

/* .nav-name{
  flex-grow: 1; 
  font-size: 2em; 
  color: #ffffff;
  /* display: block; */
  /* align-items: center;
  font-weight: lighter; 
  text-decoration: none;
  flex-grow: 1; */
  /* padding-left: 1em; */
  /* padding-top: 0.55em;
  padding-bottom: 0.05em; */
  /* height: 100%; */
/* } */

.log{
  padding-top: .2em;
  padding-bottom: .2em;
}

/* .coalition{
  font-family: Playfair;
  font-weight: 600;
  font-size: 3.0rem;
} */


.container{
  width: 80%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  justify-content: center;

}

.sec-wrapper{
  margin: 0 auto;
  padding: 1em 2em;
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom: 0.5rem
}

code {
  font-family: "Source Sans 3", sans-serif;
}

nav {
  width: 100%;
  height: 85px;
  display: flex;
  font-weight: 9;
  font-size: 1.1em;
  /* padding: 0.2rem calc((100vw - 1000px) / 19); */
  z-index: 12;

  background-image: url("./protest-nav.jpg");
  background-position: 4.2px -0.25px;
  position: fixed; 
  top: 0;
  /* font: "Source Sans 3"; */

}
/* nav */

.link-styles {

  color: #ffffff;

    display: flex;
    align-items: center;
    font-weight: 200; 
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    /* &.active {
        font-weight: bold;
    } */
    
  
}

.link-styles.active {
  font-weight: 500;
}


h1{
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bolder;
  display: block;
  font-size: 1.875rem;
  /* font-family: "Zuume Rough"; */
  color: #000000;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" */
}


h2{
  font-size: calc(1.30875rem + 0.705vw);
}

h3{
  /* display: block; */
  font-size: 1em;
  /* margin-block-start: 0.67em; */
  /* margin-block-end: 0.67em; */
  /* margin-inline-start: 0px; */
  /* margin-inline-end: 0px; */
  font-weight:lighter;
  /* display: block; */
  /* font-size: 1.875rem; */
  /* font-family: "Zuume Rough"; */
  color: #000000;
  text-align: left;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" */
}

h4{
  /* display: block; */
  font-size: 1em;
  /* margin-block-start: 0.67em; */
  /* margin-block-end: 0.67em; */
  /* margin-inline-start: 0px; */
  /* margin-inline-end: 0px; */
  font-weight:lighter;
  /* display: block; */
  /* font-size: 1.875rem; */
  /* font-family: "Zuume Rough"; */
  color: #000000;
  text-align: left;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" */
}

h5{
  /* display: block; */
  font-size: 0.5em;
  /* margin-block-start: 0.67em; */
  /* margin-block-end: 0.67em; */
  /* margin-inline-start: 0px; */
  /* margin-inline-end: 0px; */
  font-weight:lighter;
  /* display: block; */
  /* font-size: 1.875rem; */
  /* font-family: "Zuume Rough"; */
  color: #ffffff;
  text-align: center;
  align-self: center;
  justify-self: center;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" */
}

h6{
  /* display: block; */
  font-size: calc(2px + 0.8vw);
  /* margin-block-start: 0.67em; */
  /* margin-block-end: 0.67em; */
  /* margin-inline-start: 0px; */
  /* margin-inline-end: 0px; */
  font-weight:lighter;
  /* display: block; */
  /* font-size: 1.875rem; */
  /* font-family: "Zuume Rough"; */
  font-style: italic;
  color: #000000;
  text-align: right;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" */
}

h7{
  /* display: block; */
  font-size: calc(6em + 1vw);
  /* margin-block-start: 0.67em; */
  /* margin-block-end: 0.67em; */
  /* margin-inline-start: 0px; */
  /* margin-inline-end: 0px; */
  font-weight:bold;
  /* display: block; */
  /* font-size: 1.875rem; */
  /* font-family: "Zuume Rough"; */
  /* font-style: italic; */
  color: #ffffff;
  top: 0;
  /* margin-left: 0.5em; */
  text-align: center;
  align-items: center;
  /* margin-top: 5em; */
  /* margin-bottom: 0.5em; */
  font-family: "Libre Franklin", "Source Sans 3", sans-serif;
  justify-content: center;
  width: 100%;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" */
}

/* Define font sizes for different screen sizes */
/* @media screen and (max-width: 768px) {
  h7 {
    font-size: calc(4.5em + 0.8vw); 
  }
}

@media screen and (max-width: 480px) {
  h7 {
    font-size: calc(3em + 0.6vw); 
  }
} */

.centered-text {
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: center;
  margin-top: 3.5em;
  align-items: center;
  margin-bottom: 0.5em; 
}

.centered-head {
  align-items: center;
  justify-content: center;
  justify-content: center;
  align-items: center; 
}

.centered-text h7 {
  font-size: 3.7em; /* Adjust font size as needed */
  text-align: center;
  /* Any other styles you want to apply */
}

.expl{
  width: 60%;
  font-weight: lighter; 
  text-align: center;
  color: #ffffff;
  /* text-shadow: 1px 1px 1px #000000; */
  font-size: calc(1.75rem + 0.3vw);
  /* font-style: italic; */
  /* font-family: "Zuume Rough" */


}

.home {
  display: flex;
  justify-content: center;
  
}

.blurb{
  
  margin: 50px;
  align-items: center;
  font-family: "Source Sans 3", sans-serif;
}

.date{

  color: #ffffff;
  font-size: 1.25em;
  text-align: center;
  font-weight: lighter;

}
.countdown-head{
  color: #EE2A35;
  font-weight: bold;
  text-align: center;
  font-size: calc(1.75rem + 0.3vw);
  /* background-color: #ffffff; */
  /* text-shadow: 0.5px 0px 0px #000000; */
  /* font-family: "Zuume Rough" */

}

.link-styles {
  transition: color 0.3s ease; /* Add transition for color change */
}

.link-styles:hover {
  color: #EE2A35; /* Change color on hover */
}
.icon-soc{
  display:flex;
  flex-direction: row;
  flex-shrink: initial;
}
.fa {
  margin-left: 2%;
  margin-left: 2%;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease;
  color: black;
  position: absolute;
  bottom: 0%;
  padding-bottom: 1.95rem;
  padding-right: 1rem;
  color: #ffffff;
  
  overflow-x: scroll;
  
  
  
}

.fa:hover {
  color: #EE2A35;
}

.fa-instagram {
  /* background: #3B5998; */
  /* color: black; */
  left: 46%;
  padding-right: 1rem;
  
}

.fa-twitter {
  /* background: #55ACEE; */
  /* color: white; */
  left: 48%;
  padding-left: 1rem;
}